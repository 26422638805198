@import '~admin-lte';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

body {
    background-color: #303e4e;
    color: #b1b1b1;
}

.jumbotron {
    border-radius: 0;
    background:#3c8dbc;
    color:#fff;
    border-bottom:4px solid #fff;
}

.card {
    background: #f4f6f9;
    border-radius: 0;
}